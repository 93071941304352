.box-type-one {
  position: relative;
  overflow: hidden;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -25px;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: pixelsToRem(40);
    @include addBackground($bgColor: transparent, $bgImage: url('../precompile/box-stopka.svg'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center);
  }

  .icon-one {
    font-size: pixelsToRem(140);
    color: $main-secondary-color;
  }

  .bto-h3 {
    padding: pixelsToRem(15);
    margin-bottom: 0;
    font-size: pixelsToRem(24);
    font-weight: 600;
    background-color: $main-primary-color;
    color: $main-secondary-color-dark;
    text-align: center;
  }

  .bto-content {
    padding: 10px pixelsToRem(15) pixelsToRem(80) pixelsToRem(15);
    background-color: $main-alternate-color;
    color: $main-secondary-color-dark;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      text-align: center;
      margin-bottom: 0 !important;
    }
  }

  .main-btn-one {
    margin: 0 auto #{pixelsToRem(80)} auto !important;
  }

}

.boxes-rlt > .row {
  margin-top: pixelsToRem(60);
  & > div {
    position: relative;
    margin-bottom: pixelsToRem(60);
  }
}

.box-type-two {
  padding: pixelsToRem(60) 0 0 0;
  background-color: #ffffff;

  .h3 {
    font-weight: 500;
    font-size: pixelsToRem(28);
    padding: pixelsToRem(5) pixelsToRem(15);
    border-left: 30px solid $main-alternate-color;
    color: $main-secondary-color-dark;
    background: linear-gradient(90deg, #ffffff 0%, $main-alternate-color 100%);
    margin-top: pixelsToRem(20);
    margin-bottom: pixelsToRem(20);
  }

  .row > div > .h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}