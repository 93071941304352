body {
	overflow-x: hidden;
	font-family: $main-primary-font-family;
	color: $main-body-text-color;
	font-weight: 400;
	font-size: 1rem;
	line-height: $main-base-line-height;
	background-color: #ffffff;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
	line-height: $main-base-line-height;
}