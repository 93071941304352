@function pixelsToRem($pixels) {
  $remValue: ($pixels / $main-base-font-size-int) + rem;
  @return $remValue;
}

@mixin pxToRemWithRwd($property, $pixels, $rwdSizePercent: 100) {
  #{$property}: pixelsToRem($pixels);
  @if $rwdSizePercent != 100 {
    @media(max-width: 767px) {
      #{$property}: ($pixels / $main-base-font-size-int) * ($rwdSizePercent/100) + rem;
    }
  }
}

@mixin addFontSizeLineHeightPxToRem($pxFontSize, $pxLineHeight: 0, $rwdSizePercent: 100) {
  font-size: pixelsToRem($pxFontSize);
  @if $pxLineHeight > 0 {
    line-height: $pxLineHeight / $pxFontSize;
  }
  @if $rwdSizePercent != 100 {
    @media(max-width: 767px) {
      font-size: ($pxFontSize / $main-base-font-size-int) * ($rwdSizePercent/100) + rem;
    }
  }
}

@mixin addBackground($bgColor: transparent, $bgImage: none, $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center) {
  background-image: $bgImage;
  background-repeat: $bgRepeat;
  background-size: $bgSize;
  background-position: $bgPosition;
}