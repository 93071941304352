footer {

  #footer-area {
    margin-top: pixelsToRem(60);
    color: #ffffff;

    .h2 {
      font-size: pixelsToRem(24);
      margin-bottom: pixelsToRem(20);
      padding: pixelsToRem(5) pixelsToRem(10) pixelsToRem(5) pixelsToRem(20);
      color: #ffffff;
      background: linear-gradient(90deg, $main-secondary-color-dark 0%, $main-secondary-color 100%);
      border-left: 15px solid $main-primary-color;
    }

    .p-footer {
      margin-left: pixelsToRem(38);
      &.ico-footer {
        position: relative;
        margin-left: pixelsToRem(70);
        &::before {
          font-family: "bootstrap-icons";
          font-size: pixelsToRem(20);
          position: absolute;
          top: -1px;
          left: -#{pixelsToRem(34)};
        }
        &.home-ico {
          &::before {
            content: "\F424";
          }
        }
        &.email-ico {
          &::before {
            content: "\F32C";
          }
        }
        &.phone-ico {
          &::before {
            content: "\F5B4";
          }
        }
        &.facebook-ico {
          &::before {
            content: "\F344";
          }
        }
      }
    }

    .footer-top {
      position: relative;
      height: pixelsToRem(60);
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        width: calc(100% - (50% - 646.5px));
        height: pixelsToRem(60);
        top: 0;
        right: 0;
        background-color: $main-primary-color;
        @media(max-width: 1400px) {
          width: 100%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: -#{pixelsToRem(42)};
        left: calc((50% - 646.5px) - #{pixelsToRem(42)});
        width: pixelsToRem(84);
        height: pixelsToRem(84);
        background-color: #ffffff;
        transform: rotate(45deg);
        @media(max-width: 1400px) {
          left: - #{pixelsToRem(65)};
        }
      }
    }

    .footer-content {
      background-color: $main-secondary-color-dark;
      padding: pixelsToRem(40) 0;
      a {
        color: #ffffff;
      }
    }

    .footer-bottom {
      background-color: $main-secondary-color;
      padding: pixelsToRem(15) 0;
      text-align: right;
    }
  }

}