#cookies-message {
	display: flex;
	justify-content: center;
	align-items: stretch;
	padding: pixelsToRem(10);
	font-size: pixelsToRem(14);
	box-shadow: 0 0 3px 3px rgba(0,0,0,0.3);
	background: rgba(255, 255, 255, .95);
	color: $main-secondary-color;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 888;

	& > div:first-child {
		margin-right: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		//padding-right: 20px;
		//border-right: 2px solid #c8c8c8;
	}
	
	.wh-link {
		color: $main-primary-color;
		display: inline-block;
		font-weight: 600;
	}

	#accept-cookies-checkbox {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}