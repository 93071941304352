//Strona główna
header {
  padding-top: pixelsToRem(100);
  padding-bottom: pixelsToRem(60);

  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */

  @media(max-width: 767px) {
    padding-top: pixelsToRem(25);
  }

  #top-bar {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    transition: all .3s ease;

    @media(max-width: 767px) {
      display: none;
    }

    &.main-wp-bar {
      margin-top: 32px;
    }

    &.main-nav-top-sticky {
      @media(min-width: 768px) {
        transition: all .3s ease;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.3);
        background-color: rgba(255, 255, 255, 1);
        #col-logo {
          padding-top: 5px;
          #logo {
            width: 200px;
            height: 46px;
            transition: all .3s ease;
          }
        }
        #menu-top-primary {
          & > li {
            padding-top: 8px;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  #col-logo {
    padding-top: 10px;
    #logo {
      display: block;
      max-width: 260px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      transition: all .3s ease;
      @media(max-width: 767px) {
        display: none;
      }
    }
  }

  .htc-out {
    position: relative;
    background: $main-primary-color;
    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: pixelsToRem(80);
      background-color: #ffffff;
      bottom: 0;
      left: 0;

      @media(max-width: 1399px) {
        height: pixelsToRem(50);
      }
    }
  }

  #header-top-content {
    display: flex;
    flex-flow: row;
    min-height: 650px;
    position: relative;
    padding-bottom: pixelsToRem(80);
    background-color: #ffffff;

    @media(max-width: 1399px) {
      min-height: 500px;
      padding-bottom: pixelsToRem(50);
    }
    @media(max-width: 991px) {
      min-height: 420px;
    }
    @media(max-width: 767px) {
      min-height: 320px;
    }
    @media(max-width: 550px) {
      min-height: 250px;
    }
    @media(max-width: 420px) {
      padding-bottom: pixelsToRem(50);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      width: pixelsToRem(840);
      height: pixelsToRem(401);
      @include addBackground($bgColor: transparent, $bgImage: url('../precompile/layer_right_bottom.png'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center );
      @media(max-width: 1399px) {
        width: pixelsToRem(750);
        height: pixelsToRem(359);
      }
      @media(max-width: 1199px) {
        width: pixelsToRem(640);
        height: pixelsToRem(306);
      }
      @media(max-width: 991px) {
        background-image: url('../precompile/layer_right_bottom-alt.png');
        width: pixelsToRem(500);
        height: pixelsToRem(239);
      }
      @media(max-width: 420px) {
        width: pixelsToRem(400);
        height: pixelsToRem(191);
      }
      @media(max-width: 350px) {
        width: pixelsToRem(350);
        height: pixelsToRem(167);
      }
    }

    .htc-left {
      flex: 1;
      position: relative;

      @media(max-width: 991px) {
        @include addBackground($bgColor: transparent, $bgImage: url('../precompile/laptop.jpg'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center);
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: pixelsToRem(150);
        height: pixelsToRem(222);
        @include addBackground($bgColor: transparent, $bgImage: url('../precompile/triangle_top_left.png'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center);
        @media(max-width: 991px) {
          background-image: url('../precompile/triangle_top_left_rwd.png');
          width: pixelsToRem(125);
          height: pixelsToRem(125);
        }
        @media(max-width: 500px) {
          width: pixelsToRem(100);
          height: pixelsToRem(100);
        }
      }

      .htc-in-top {
        position: absolute;
        width: 100%;
        height: pixelsToRem(100);
        background-color: #ffffff;
        top: 0;
        left: pixelsToRem(50);
        transform: skewX(-45deg);
        @media(max-width: 991px) {
          display: none;
        }
      }

      .htc-in-bottom {
        position: relative;
        overflow: hidden;
        margin-top: pixelsToRem(100);
        background-color: $main-secondary-color-dark;
        background: linear-gradient(135deg, $main-secondary-color-dark 0%, $main-secondary-color-dark 40%, $main-secondary-color 100%);
        height: calc(100% - #{pixelsToRem(100)});
        padding: pixelsToRem(80) pixelsToRem(50) pixelsToRem(50) pixelsToRem(80);
        color: #ffffff;
        font-size: pixelsToRem(20);

        .h1, p {
          position: relative;
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -15%;
          right: -15%;
          width: 100%;
          height: 100%;
          @include addBackground($bgColor: $main-secondary-color-dark, $bgImage: url('../precompile/sygnet_bg.svg'), $bgRepeat: no-repeat, $bgSize: 86%, $bgPosition: bottom right);
          @media(max-width: 1399px) {
            display: none;
          }
        }

        @media(max-width: 991px) {
          margin-top: 0;
          background-color: rgba(24, 36, 60, .9);
          background-image: none;
          height: 100%;
          padding-bottom: pixelsToRem(150);
        }

        @media(max-width: 500px) {
          padding: pixelsToRem(100) pixelsToRem(20) pixelsToRem(150) pixelsToRem(20);
        }

        @media(max-width: 420px) {
          padding-bottom: pixelsToRem(120);
        }
      }

    }

    .htc-right {
      flex: 1;
      @include addBackground($bgColor: transparent, $bgImage: url('../precompile/laptop.jpg'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: right center);
      margin-bottom: pixelsToRem(80);
      @media(max-width: 991px) {
        display: none;
      }
    }

  }

  //Podstrona
  &.sub-page {

    @media(max-width: 767px) {
      padding-top: pixelsToRem(60);
    }

    #header-top-content {
      min-height: unset;

      &::after {
        width: pixelsToRem(550);
        height: pixelsToRem(263);
        background-image: url('../precompile/layer_right_bottom-alt.png');
        @media(max-width: 991px) {
          width: pixelsToRem(350);
          height: pixelsToRem(168);
        }
        @media(max-width: 500px) {
          width: pixelsToRem(300);
          height: pixelsToRem(144);
        }
      }

      .htc-left {
        //z-index: 2;
        @include addBackground($bgColor: transparent, $bgImage: url('../precompile/laptop.jpg'), $bgRepeat: no-repeat, $bgSize: cover, $bgPosition: center);

        &::before {
          background-image: url('../precompile/triangle_top_left_rwd.png');
          width: pixelsToRem(125);
          height: pixelsToRem(125);
          @media(max-width: 991px) {
            width: pixelsToRem(80);
            height: pixelsToRem(80);
          }
        }

        .htc-in-top {
          display: none;
        }

        .htc-in-bottom {
          margin-top: 0;
          background-color: rgba(24, 36, 60, .9);
          height: 100%;
          padding: pixelsToRem(100) pixelsToRem(140);
          background-image: none;
          &::before {
            display: none;
          }
          @media(max-width: 991px) {
            padding: pixelsToRem(50) pixelsToRem(70);
          }
          .h1 {
            margin-bottom: 0;
            @include addFontSizeLineHeightPxToRem(64, 0, 100);
            @media(max-width: 1399px) {
              font-size: pixelsToRem(50);
            }
            @media(max-width: 991px) {
              font-size: pixelsToRem(36);
            }
            @media(max-width: 767px) {
              font-size: pixelsToRem(28);
            }
          }
        }

      }

      .htc-right {
        display: none;
      }

    }

  }

}