.btn {
  color: #ffffff;
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
  }
}

.main-btn-one {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  padding: 16px 65px 16px 16px;
  min-width: 220px;
  background-color: $main-secondary-color;
  color: #fff !important;
  text-transform: uppercase;
  font-size: pixelsToRem(18);
  font-weight: 700;
  border: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 25%;
    width: 0;
    height: 50%;
    background-color: #fff;
    transition: all .3s ease;
  }

  &:hover, &:focus {
    color: #fff;
    text-decoration: none;

    &::before {
      width: 15px;
      transition: all .3s ease;
    }

    .btn-title {
      transition: all .3s ease;
      margin-left: 15px;
    }

    .btn-decor {
      width: 36px;
      height: 36px;
      margin-right: -5px;
      top: calc(50% - 18px);
      transition: all .3s ease;
      &::before {
        transition: all .3s ease;
        width: 16px;
        height: 24px;
      }
    }
  }

  .btn-title {
    text-align: left;
    width: 100%;
    transition: all .3s ease;
    margin-top: 2px;
  }

  .btn-decor {
    position: absolute;
    right: 20px;
    top: calc(50% - 13px);
    transition: all .3s ease;
    background-color: #fff;
    padding: 0;
    width: 26px;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 11px;
      height: 16px;
      @include addBackground($bgColor: transparent, $bgImage: url('../precompile/arr_rgt.svg'), $bgRepeat: no-repeat, $bgSize: contain, $bgPosition: center);
      transition: all .3s ease;
    }
  }

  & > div {
    display: inline-block;
  }

  &.main-btn-one-alt {
    background-color: $main-primary-color;
    .btn-decor {
      &::before {
        background-image: url('../precompile/arr_rgt-alt.svg');
      }
    }
  }
}

a {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}