.slicknav_menu {
	display: none;
	background-color: #fff;
	padding-left: 0;
	padding-right: 0;
	position: fixed;
	z-index: 9;
	width: 100%;
	top: 0;
	box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
	box-sizing: border-box;

	#mob-logo {
		display: inline-block;
		width: 180px;
		height: 42px;
		margin-top: 5px;
		margin-left: 10px;
	}
	
	@media(max-width: 767px) {
		display: block;
	}
	
	.slicknav_btn {
		position: absolute;
		right: 0;
		top: 3px;
		background-color: #fff;
		padding: 9px 8px 4px 8px;
		.slicknav_icon {
			font-size: 32px;
			.slicknav_icon-bar {
				background-color: $main-primary-color;
			}
		}
	}
	
	.slicknav_nav {
		background-color: $main-primary-color;
		border-top: 5px solid #ffffff;
		
		& > li > a {
			font-size: 20px;
			margin: 3px 0;
			font-weight: 500;
			padding: 10px 10px 5px 10px;
			text-decoration: none;
			&.slicknav_row, &.slicknav_row > a {
				font-size: 20px;
			}
		}
		
		a {
			font-size: 18px;
			border-radius: 0;
			color: #fff;
			margin: 0;
			text-decoration: none;
			
			&:hover, &:focus {
				background-color: rgba(255, 255, 255, .5);
				color: $main-secondary-color;
			}
		}
		
		.slicknav_row {
			color: $main-primary-color;
			&:hover, &:focus {
				border-radius: 0;
				background-color: rgba(39, 58, 86, .2);
				
				a {
					color: #fff;
				}
				
				.slicknav_arrow {
					color:$main-primary-color;
				}
			}
			
			.slicknav_arrow {
				color: #fff;
				font-size: 1em;
			}
		}
		
	}
	
	.slicknav_icon-bar {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}
	
	#mob-logo {
		img {
			margin-top: 12px;
			margin-left: 10px;
		}
	}
}