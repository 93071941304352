@import 'variables-start';
@import 'vars-frameworks';

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "../../node_modules/slicknav/scss/slicknav";
@import "../../node_modules/lightbox2/dist/css/lightbox.css";
@import "../../node_modules/animate.css/animate.css";

//customs
@import 'fonts';
@import 'variables';
@import 'program';
@import 'variables-end';
@import 'html';
@import 'body';
@import 'general';
@import 'header';
@import 'main-div-body';
@import 'footer';
@import 'animations';
@import 'buttons-and-links';
@import 'sections';
@import 'lists';
@import "menu-top-primary";
@import "boxes";
@import "slicknav";
@import "forms";
@import "cookie_box";