.main-section {
  margin-top: pixelsToRem(60);

  &:first-child {
    margin-top: 0;
  }

  a:not(.btn) {
    text-decoration: underline;
    color: $main-secondary-color-dark;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .h2-out {
    position: relative;
    width: 100%;
    background-color: #e8e8e8;

    .h2 {
      position: relative;
      border-left: 30px solid $main-primary-color;
      padding: pixelsToRem(15);
      font-size: pixelsToRem(36);
      color: $main-secondary-color;
      background: linear-gradient(90deg, #ffffff 0%, $main-alternate-color-dark 100%);
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
      margin-left: calc(50% - 646.5px);
      width: 1293px;

      //&::before {
      //  content: "";
      //  position: absolute;
      //  bottom: 0;
      //  left: pixelsToRem(15);
      //  width: calc(100% - #{pixelsToRem(15)});
      //  height: 5px;
      //  background-color: $main-alternate-color-dark;
      //}

      @media(max-width: 1399px) {
        width: calc(100% - (2*(50% - 556px)));
        margin-left: calc(50% - 556px);
      }
      @media(max-width: 1199px) {
        width: calc(100% - (2*(50% - 466px)));
        margin-left: calc(50% - 466px);
      }
      @media(max-width: 991px) {
        width: calc(100% - (2*(50% - 346.5px)));
        margin-left: calc(50% - 346.5px);
      }
      @media(max-width: 767px) {
        border-width: 20px;
        font-size: 24px;
        width: calc(100% - (2*(50% - 258px)));
        margin-left: calc(50% - 258px);
      }
      @media(max-width: 540px) {
        width: calc(100% - 22px);
        margin-left: 11px;
      }
    }
  }

  .content-area-full {
    border-top: 5px solid #ffffff;
    background-color: #ffffff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: pixelsToRem(10);
      background-color: $main-alternate-color-dark;
      top: 0;
      left: 0;
    }
    .p-icons {
      margin-left: pixelsToRem(40);
      position: relative;
      &::before {
        font-family: "bootstrap-icons";
        font-size: pixelsToRem(20);
        position: absolute;
        top: -1px;
        left: -#{pixelsToRem(34)};
      }
      &.home-ico {
        &::before {
          content: "\F424";
        }
      }
      &.email-ico {
        &::before {
          content: "\F32C";
        }
      }
      &.phone-ico {
        &::before {
          content: "\F5B4";
        }
      }
      &.facebook-ico {
        &::before {
          content: "\F344";
        }
      }
      &.info-ico {
        &::before {
          content: "\F430";
        }
      }
    }
  }
	
}

.home {
  .main-section {
    margin-top: 0;
  }
}