.form-one {

  .form-group {
    position: relative;
    padding-bottom: pixelsToRem(30);

    @media(max-width: 767px) {
      padding-bottom: 20px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    @media(max-width: 767px) {
      margin-top: pixelsToRem(8);
      margin-bottom: pixelsToRem(8);
    }

  label {
    &:not(.form-check-label).required {
      &::after {
        content: "*";
        color: $danger;
        font-size: pixelsToRem(16);
        position: absolute;
        margin-left: 5px;
      }
    }
    &.form-check-label {
      position: relative;
      padding-left: pixelsToRem(5);
      left: 0;
      .main-star {
        position: relative;
        display: inline-block;
        width: pixelsToRem(10);
        &::before {
          color: $danger;
          font-size: pixelsToRem(16);
          content: "*";
          position: absolute;
          top: pixelsToRem(-16);
          left: pixelsToRem(2);
        }
      }
    }
  }

    label {
      position: absolute;
      z-index: 1;
      font-weight: 300;
      top: pixelsToRem(8);
      left: pixelsToRem(15);
      @include addFontSizeLineHeightPxToRem(16);
      cursor: text;
    }

    input {
      color: $main-body-text-color;
      position: relative;

      &[type="tel"], &[type="email"], &[type="text"], &[type="url"] {
        background-color: #fff;
      }
    }

    .form-check-input {
      border-radius: 0 !important;
      border-color: $main-primary-color;
      background-color: #ffffff;
      position: relative;
      width: pixelsToRem(20);
      height: pixelsToRem(20);
      margin-top: pixelsToRem(10);
      &::after {
        display: none;
        content: "\F26E";
        font-family: bootstrap-icons !important;
        position: absolute;
        top: 0;
        left: 0;
        font-size: pixelsToRem(20);
        line-height: pixelsToRem(20);
        color: $main-secondary-color;
      }
      &:checked[type="checkbox"] {
        background-color: #fff;
        border-color: $main-secondary-color;
        background-image: none;
        &::after {
          display: block;
        }
      }
    }

    .wpcf7-not-valid-tip {
      font-size: pixelsToRem(12);
      margin-top: 4px;
    }

    textarea {
      min-height: pixelsToRem(233);
    }

    .text-small-one {
     font-size: 11px;
      font-weight: 300;
    }
  }

}