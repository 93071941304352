#menu-top-primary {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: right;

  @media(max-width: 767px) {
    display: none;
  }

  & > li {
    display: inline-block;
    padding-top: pixelsToRem(14);
    padding-left: pixelsToRem(20);

    @media(max-width: 991px) {
      padding-top: pixelsToRem(10);
      padding-left: 0;
    }

    & > a {
      font-weight: 500;
      display: block;
      text-decoration: none;
      color: $main-secondary-color;
      text-transform: uppercase;
      position: relative;
      padding: pixelsToRem(5) pixelsToRem(10);

      @media(max-width: 991px) {
        font-size: pixelsToRem(16);
      }

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: calc(50% - #{pixelsToRem(1)});
        left: 50%;
        background-color: $main-primary-color;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 50%;
        background-color: $main-primary-color;
        z-index: -1;
      }

      &:not(.active):hover, &:not(.active):focus {
        color: #ffffff;
        &::before {
          bottom: 0;
          width: 100%;
          height: 100%;
          left: 0;
          transition: all .3s ease;
        }
      }

      &.active {
        &::before {
          bottom: 0;
          left: pixelsToRem(10);
          width: calc(100% - #{pixelsToRem(20)});
        }
        &:hover, &:focus {
          &:before {
            width: 0;
            left: 50%;
          }
          &:after {
            width: calc(100% - #{pixelsToRem(20)});
            left: pixelsToRem(10);
            bottom: 0;
            transition: all .3s ease;
          }
        }
      }
    }

    ul {
      display: none;
    }
  }
}