p {
	@include pxToRemWithRwd(margin-bottom, 10);
	&:last-child {
		margin-bottom: 0;
	}
}

.main-primary-color-text {
	color: $main-primary-color !important;
}

.main-primary-color-bg {
	background-color: $main-primary-color !important;
}

.main-secondary-color-text {
	color: $main-secondary-color !important;
}

.main-secondary-color-bg {
	background-color: $main-secondary-color !important;
}

.h1 {
	@include addFontSizeLineHeightPxToRem(28, $rwdSizePercent: 100);
	font-weight: 900;
	margin-bottom: pixelsToRem(30);
	@media(max-width: 500px) {
		font-size: pixelsToRem(22);
	}
}

.main-mt {
	margin-top: pixelsToRem(60);
}

.main-mb {
	margin-bottom: pixelsToRem(60);
}

.main-pt {
	padding-top: pixelsToRem(60);
}

.main-pb {
	padding-bottom: pixelsToRem(60);
}
